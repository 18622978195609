"use client";
import { motion } from "framer-motion";
import { useState } from "react";
import axios from "axios";
import { Button, Input, Modal, message } from "antd";

const Account = () => {
  const [otp, setOtp] = useState("");
  const [data, setData] = useState({
    email: "",
    phone: "",
    reason: "",
  });

  const resetForm = () => {
    setData({
      email: "",
      phone: "",
      reason: "",
    });
    setOtp("");
  };

  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  function handleSubmit(event: any) {
    axios
      .post("https://g1tar.xyz.co.id/api/auth/request-delete-account", data, {
        headers: {
          "GTIP-Tenant-Id": "79d56e0c-0d7a-4cdd-95c5-94b3fe037d59",
        },
      })
      .then(function (response) {
        console.log(response);
        showModal();
      })
      .catch(function (error) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: error.response.data.userMessage,
        });
      });

    event.preventDefault();
  }

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    axios
      .post(
        "https://g1tar.xyz.co.id/api/auth/delete-account",
        { ...data, otp },
        {
          headers: {
            "GTIP-Tenant-Id": "79d56e0c-0d7a-4cdd-95c5-94b3fe037d59",
          },
        }
      )
      .then(function (response) {
        console.log(response);
        setConfirmLoading(true);
        messageApi.open({
          type: "success",
          content: "Berhasil menghapus Acoount",
        });
        setTimeout(() => {
          setOpen(false);
          setConfirmLoading(false);
          resetForm();
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: error.response?.data?.userMessage ?? "Gagal menghapus Account",
        });
      });
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <>
      {contextHolder}
      {/* <!-- ===== SignUp Form Start ===== --> */}
      <section className="pb-12.5 pt-32.5 lg:pb-25 lg:pt-45 xl:pb-30 xl:pt-5">
        <div className="mx-auto max-w-c-1235 px-4 md:px-8 xl:px-0">
          <div className="absolute left-0 top-0 -z-1 h-2/3 w-full rounded-lg bg-gradient-to-t from-transparent to-[#dee7ff47]"></div>
          <div className="absolute bottom-17.5 left-0 -z-1 h-1/3 w-full">
            <img src="/images/shape-dotted-light.svg" alt="Dotted" />
          </div>

          <motion.div
            variants={{
              hidden: {
                opacity: 0,
                y: -20,
              },

              visible: {
                opacity: 1,
                y: 0,
              },
            }}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 1, delay: 0.1 }}
            viewport={{ once: true }}
            className="animate_top rounded-lg bg-white px-7.5 pt-7.5 shadow-solid-8 xl:px-15 xl:pt-15"
          >
            <h2 className="mb-10 text-center text-3xl font-semibold text-black l:text-sectiontitle2">Delete Account</h2>
            <div className="mb-7.5 flex flex-col gap-7.5 lg:mb-12.5 lg:flex-row lg:gap-14">
              <div className="items-center justify-center">
                <p className="text-body-color w-full px-5 text-base">Data yang akan dihapus saat proses penghapusan akun berupa:</p>
                <div className="flex items-center">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Profile</strong>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Wishlist</strong>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Keranjang</strong>
                  </div>
                </div>
              </div>

              <div className="items-center justify-center">
                <p className="text-body-color w-full px-5 text-base">Data tidak dihapus:</p>
                <div className="flex items-center">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Riwayat pesanan</strong>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Riwayat pembayaran</strong>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Ulasan</strong>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Pesan / Chat</strong>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="mb-7.5 flex flex-col gap-7.5 lg:mb-12.5 lg:flex-row lg:justify-between lg:gap-14">
                <input
                  name="email"
                  type="email"
                  placeholder="Email address"
                  value={data.email}
                  onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                  className="w-full border-b border-stroke bg-transparent pb-3.5 focus:border-waterloo focus:placeholder:text-black focus-visible:outline-none lg:w-1/2"
                />

                <input
                  name="phone"
                  type="phone"
                  placeholder="Phone"
                  value={data.phone}
                  onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                  className="w-full border-b border-stroke bg-transparent pb-3.5 focus:border-waterloo focus:placeholder:text-black focus-visible:outline-none lg:w-1/2"
                />
              </div>

              <div className="mb-7.5 flex flex-col gap-7.5 lg:mb-12.5 lg:flex-row lg:justify-between lg:gap-14">
                <textarea
                  name="reason"
                  placeholder="Reason"
                  value={data.reason}
                  onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                  className="w-full border-b border-stroke bg-transparent pb-3.5 focus:border-waterloo focus:placeholder:text-black focus-visible:outline-none lg:w-1/2"
                ></textarea>
              </div>

              <div className="flex flex-wrap gap-10 md:justify-between xl:gap-15 pb-15">
                <div className="mb-4 flex items-center"></div>

                <button type="submit" aria-label="signup with email and password" className="inline-flex items-center gap-2.5 rounded-full bg-black px-6 py-3 font-medium text-white duration-300 ease-in-out hover:bg-blackho">
                  Delete Account
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      </section>

      <Modal
        title="Masukan OTP"
        open={open}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <Button onClick={handleOk}>Ok</Button>
          </>
        )}
      >
        <Input placeholder="OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
      </Modal>
      {/* <!-- ===== SignUp Form End ===== --> */}
    </>
  );
};

export default Account;
