import { Menu } from "../../types/menu";

const menuData: Menu[] = [
  {
    id: 1,
    title: "Home",
    newTab: false,
    path: "/",
  },
  {
    id: 2,
    title: "Privacy & Policy",
    newTab: false,
    path: "/privacy",
  },
  {
    id: 3,
    title: "Terms & Conditions",
    newTab: false,
    path: "/terms",
  },
  {
    id: 4,
    title: "FAQ",
    newTab: false,
    path: "/faq",
  },
  {
    id: 5,
    title: "Delete Account",
    newTab: false,
    path: "/deleteAccount",
  },
];

export default menuData;
