"use client";

import { motion } from "framer-motion";
import "./privacy.css";

const Privacy = () => {
  return (
    <>
      <section className="overflow-hidden pb-20 pt-35 md:pt-40 xl:pb-30 xl:pt-5">
        <div className="mx-auto max-w-c-1235 px-4 md:px-8 xl:px-0">
          <div className="flex items-center gap-8 ">
            <motion.div
              variants={{
                hidden: {
                  opacity: 0,
                  x: 20,
                },

                visible: {
                  opacity: 1,
                  x: 0,
                },
              }}
              initial="hidden"
              whileInView="visible"
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
            >
              <h2 className="relative mt-6 text-3xl font-bold text-black l:text-hero">Privacy Policy</h2>
              <p>Last updated: December 04, 2023</p>
              <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
              <p>
                We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the
                help of the{" "}
                <a href="https://www.termsfeed.com/privacy-policy-generator/" target="_blank">
                  Privacy Policy Generator
                </a>
                .
              </p>
              <h2 className="relative mt-6 text-3xl font-bold text-black l:text-hero">Interpretation and Definitions</h2>
              <div>
                <h3 className="mb-0.5 text-metatitle2 text-black">Interpretation</h3>
                <p>
                  The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                </p>
              </div>
              <div>
                <h3 className="mb-0.5 text-metatitle2 text-black">Definitions</h3>
                <p>For the purposes of this Privacy Policy:</p>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">01</p>
                  </div>
                  <div className="w-3/4">
                    <strong>Account</strong>
                    <p>means a unique account created for You to access our Service or parts of our Service.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">02</p>
                  </div>
                  <div className="w-3/4">
                    <strong>Affiliate</strong>
                    <p>
                      means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for
                      election of directors or other managing authority.
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">03</p>
                  </div>
                  <div className="w-3/4">
                    <strong>Application</strong>
                    <p>refers to Borongan, the software program provided by the Company.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">04</p>
                  </div>
                  <div className="w-3/4">
                    <strong>Company</strong>
                    <p>(referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Borongan.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">05</p>
                  </div>
                  <div className="w-3/4">
                    <strong>Country</strong>
                    <p>refers to: Indonesia</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">06</p>
                  </div>
                  <div className="w-3/4">
                    <strong>Device</strong>
                    <p>means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">07</p>
                  </div>
                  <div className="w-3/4">
                    <strong>Personal Data</strong>
                    <p>is any information that relates to an identified or identifiable individual.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">08</p>
                  </div>
                  <div className="w-3/4">
                    <strong>Service</strong>
                    <p>refers to the Application.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">09</p>
                  </div>
                  <div className="w-3/4">
                    <strong>Service Provider</strong>
                    <p>
                      means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf
                      of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">10</p>
                  </div>
                  <div className="w-3/4">
                    <strong>Third-party Social Media Service</strong>
                    <p>refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">11</p>
                  </div>
                  <div className="w-3/4">
                    <strong>Usage Data</strong>
                    <p>refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">12</p>
                  </div>
                  <div className="w-3/4">
                    <strong>You</strong>
                    <p>means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                  </div>
                </div>
              </div>

              <h2 className="relative mt-6 text-3xl font-bold text-black l:text-hero">Collecting and Using Your Personal Data</h2>
              <div>
                <h3 className="mb-0.5 text-metatitle2 text-black">Types of Data Collected</h3>
                <h4 className="mb-0.5 text-metatitle3 text-black">Personal Data</h4>
                <p>
                  While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
                </p>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Email address</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>First name and last name</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Phone number</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Address, State, Province, ZIP/Postal code, City</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Usage Data</strong>
                  </div>
                </div>

                <h4 className="mb-0.5 text-metatitle3 text-black">Usage Data</h4>
                <p>Usage Data is collected automatically when using the Service.</p>
                <p>
                  Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent
                  on those pages, unique device identifiers and other diagnostic data.
                </p>
                <p>
                  When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of
                  Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
                </p>
                <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                <h4>Information from Third-Party Social Media Services</h4>
                <p>The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:</p>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Google</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Facebook</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Instagram</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Twitter</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>LinkedIn</strong>
                  </div>
                </div>
                <p>
                  If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as
                  Your name, Your email address, Your activities or Your contact list associated with that account.
                </p>
                <p>
                  You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or
                  otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.
                </p>
                <h4 className="mb-0.5 text-metatitle3 text-black">Information Collected while Using the Application</h4>
                <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</p>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Information regarding your location</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Pictures and other information from your Device's camera and photo library</strong>
                  </div>
                </div>
                <p>
                  We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on
                  Your device.
                </p>
                <p>You can enable or disable access to this information at any time, through Your Device settings.</p>
              </div>

              <div>
                <h3 className="mb-0.5 text-metatitle2 text-black">Use of Your Personal Data</h3>
                <p>The Company may use Personal Data for the following purposes:</p>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">01</p>
                  </div>
                  <div className="w-3/4">
                    <strong>To provide and maintain our Service</strong>
                    <p>including to monitor the usage of our Service.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">02</p>
                  </div>
                  <div className="w-3/4">
                    <strong>To manage Your Account</strong>
                    <p>to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">03</p>
                  </div>
                  <div className="w-3/4">
                    <strong>For the performance of a contract</strong>
                    <p>the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">04</p>
                  </div>
                  <div className="w-3/4">
                    <strong>To contact You</strong>
                    <p>
                      To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the
                      functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">05</p>
                  </div>
                  <div className="w-3/4">
                    <strong>To provide You</strong>
                    <p>
                      with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive
                      such information.
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">06</p>
                  </div>
                  <div className="w-3/4">
                    <strong>To manage Your requests</strong>
                    <p>To attend and manage Your requests to Us.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">07</p>
                  </div>
                  <div className="w-3/4">
                    <strong>For business transfers</strong>
                    <p>
                      We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of
                      bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">08</p>
                  </div>
                  <div className="w-3/4">
                    <strong>For other purposes</strong>
                    <p>
                      We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services,
                      marketing and your experience.
                    </p>
                  </div>
                </div>
                <p>We may share Your personal information in the following situations:</p>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">01</p>
                  </div>
                  <div className="w-3/4">
                    <strong>With Service Providers</strong>
                    <p>We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">02</p>
                  </div>
                  <div className="w-3/4">
                    <strong>For business transfers</strong>
                    <p>
                      We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">03</p>
                  </div>
                  <div className="w-3/4">
                    <strong>With Affiliates</strong>
                    <p>
                      We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners
                      or other companies that We control or that are under common control with Us.
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">04</p>
                  </div>
                  <div className="w-3/4">
                    <strong>With business partners</strong>
                    <p>We may share Your information with Our business partners to offer You certain products, services or promotions.</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">05</p>
                  </div>
                  <div className="w-3/4">
                    <strong>With other users</strong>
                    <p>
                      when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or
                      register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to
                      view descriptions of Your activity, communicate with You and view Your profile.
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-15 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">06</p>
                  </div>
                  <div className="w-3/4">
                    <strong>With Your consent</strong>
                    <p>We may disclose Your personal information for any other purpose with Your consent.</p>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="mb-0.5 text-metatitle2 text-black">Retention of Your Personal Data</h3>
                <p>
                  The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
                  obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                </p>
                <p>
                  The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the
                  functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
                </p>
              </div>

              <div>
                <h3 className="mb-0.5 text-metatitle2 text-black">Transfer of Your Personal Data</h3>
                <p>
                  Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred
                  to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
                </p>
                <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                <p>
                  The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a
                  country unless there are adequate controls in place including the security of Your data and other personal information.
                </p>
              </div>

              <div>
                <h3 className="mb-0.5 text-metatitle2 text-black">Delete Your Personal Data</h3>
                <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
                <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
                <p>
                  You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also
                  contact Us to request access to, correct, or delete any personal information that You have provided to Us.
                </p>
                <p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
              </div>

              <div>
                <h3 className="mb-0.5 text-metatitle2 text-black">Disclosure of Your Personal Data</h3>
                <h4 className="mb-0.5 text-metatitle3 text-black">Business Transactions</h4>
                <p>
                  If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
                </p>
                <h4 className="mb-0.5 text-metatitle3 text-black">Law enforcement</h4>
                <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                <h4 className="mb-0.5 text-metatitle3 text-black">Other legal requirements</h4>
                <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Comply with a legal obligation</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Protect and defend the rights or property of the Company</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Prevent or investigate possible wrongdoing in connection with the Service</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Protect the personal safety of Users of the Service or the public</strong>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                    <p className="text-metatitle2 font-semibold text-black">-</p>
                  </div>
                  <div className="w-4/4">
                    <strong>Protect against legal liability</strong>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="mb-0.5 text-metatitle2 text-black">Security of Your Personal Data</h3>
                <p>
                  The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to
                  protect Your Personal Data, We cannot guarantee its absolute security.
                </p>
              </div>

              <h2 className="relative mt-6 text-3xl font-bold text-black l:text-hero">Children's Privacy</h2>
              <p>
                Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has
                provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information
                from Our servers.
              </p>
              <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
              <h2 className="relative mt-6 text-3xl font-bold text-black l:text-hero">Links to Other Websites</h2>
              <p>
                Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every
                site You visit.
              </p>
              <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
              <h2 className="relative mt-6 text-3xl font-bold text-black l:text-hero">Changes to this Privacy Policy</h2>
              <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
              <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
              <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
              <h2 className="relative mt-6 text-3xl font-bold text-black l:text-hero">Contact Us</h2>
              <p>If you have any questions about this Privacy Policy, You can contact us:</p>
              <div className="flex items-center gap-2">
                <div className="flex h-8 w-15 items-center justify-center rounded-[50%] border border-stroke">
                  <p className="text-metatitle2 font-semibold text-black">-</p>
                </div>
                <div className="w-4/4">
                  <strong>By email: kontak@kalanusa.co.id</strong>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
