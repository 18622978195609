import { Layout, Menu, theme } from "antd";
import React from "react";
import "./layout.css";
import { Outlet, Link } from "react-router-dom";

import { useState } from "react";
import menuData from "./layoutMenu";

const { Header, Content, Footer } = Layout;

const AppLayout: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [dropdownToggler, setDropdownToggler] = useState(false);
  const [stickyMenu, setStickyMenu] = useState(false);

  // Sticky menu
  const handleStickyMenu = () => {
    if (window.scrollY >= 80) {
      setStickyMenu(true);
    } else {
      setStickyMenu(false);
    }
  };

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          background: "white",
        }}
      >
        <div className="logo">
          <img src="/images/logo-borongan-light.png" alt="logo" width={140} height={30} />
        </div>
        <div className="relative max-w-c-1390 items-center justify-between px-4 md:px-8 xl:flex 2xl:px-0">
          <Menu className="menu" theme="light" mode="horizontal">
            <div
              className={`invisible h-0 w-full items-center justify-between xl:visible xl:flex xl:h-auto xl:w-full ${
                navigationOpen && "navbar !visible mt-4 h-auto max-h-[400px] rounded-md bg-white p-7.5 shadow-solid-5 dark:bg-blacksection xl:h-auto xl:p-0 xl:shadow-none xl:dark:bg-transparent"
              }`}
            >
              <nav>
                <ul className="flex flex-col gap-5 xl:flex-row xl:items-center xl:gap-10">
                  {menuData.map((menuItem, key) => (
                    <li key={key} className={menuItem.submenu && "group relative"}>
                      {menuItem.submenu ? (
                        <>
                          <button onClick={() => setDropdownToggler(!dropdownToggler)} className="flex cursor-pointer items-center justify-between gap-3 hover:text-primary">
                            {menuItem.title}
                            <span>
                              <svg className="h-3 w-3 cursor-pointer fill-waterloo group-hover:fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                              </svg>
                            </span>
                          </button>

                          <ul className={`dropdown ${dropdownToggler ? "flex" : ""}`}>
                            {menuItem.submenu.map((item, key) => (
                              <li key={key} className="hover:text-primary">
                                <Link to={item.path || "#"}>{item.title}</Link>
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <Link to={`${menuItem.path}`}>{menuItem.title}</Link>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </Menu>
        </div>
      </Header>
      <Content className="site-layout" style={{ padding: "50px 50px" }}>
        <Outlet />
      </Content>
      <Footer style={{ textAlign: "center" }}>Borongan ©2023 Dibuat oleh hati 💙</Footer>
    </Layout>
  );
};

export default AppLayout;
